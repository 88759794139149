import React, {useRef} from 'react'
import './contact.css'
import Socials from '../../components/socials/Socials'
import { AiOutlineSend } from 'react-icons/ai'
import emailjs from '@emailjs/browser'
import { toast, ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import ReCaptcha from "react-google-recaptcha"
import contact_avatar from '../../assets/contact_avatar.png'

const showToast = (type) => {
  switch(type) {
    case 1:
      return (
        toast.success("Thank you! Your message has been sent successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          autoClose: 10000
        }
      ));
    case 2:
      return (    
        toast.info("Prince will respond to your message via email within 1 to 2 days.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          autoClose: 8000,
          delay: 2000
        }
      ));
    case 3:
      return (
        toast.error("Error processing request. Check internet or try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        }
      ));
    case 4:
      return (    
        toast.info("Please check the box to verify that you're not a robot.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          autoClose: 10000
        }
      ));
    case 5:
      return (
        toast.error("Email service error. Contact Prince on social media to ensure message delivery.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        }
      ));
    default:
      return null;
  }
}

const Contact = () => {

  const captchaRef = useRef(null);
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID, 
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
      e.target,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    ).then((result) => {
      if (result.status === 200) {
        showToast(1);
        showToast(2);
        e.target.reset();
        captchaRef.current.reset();
      } 
      else {
        showToast(5);
      }
    }, (error) => {
      if (error.status === 400 && error.text.toLowerCase().includes('recaptcha')) {
        showToast(4);
      }
      else {
        showToast(3);
      }
    });
  };

  return (
    <div className='contact--section' id="connect">
      <div className="contact">
        <div className="contact__details">
          <Socials/>
          <img src={contact_avatar} alt="" />
        </div>
        <form className="contact__form"  onSubmit={sendEmail}>
          <div className="form--title">Get in Touch</div>
          <div className="form--name">
            <input type="text" placeholder="First Name " name="firstName" required />
            <input type="text" placeholder="Last Name (optional)" name="lastName" />
          </div>
          <div className="form--contact">
            <input type="email" placeholder="Email Address" name="emailAddress" required />
            <input type="text" placeholder="Phone No. (optional)" name="phoneNumber" />
          </div>
          <div className="form--message">
            <textarea type="text" placeholder='Message' rows={5} maxLength="250" name="message" required />
          </div>
          <div className="captcha" style={{transform:"scale(.9)", transformOrigin:"0 0"}}>
            <ReCaptcha ref={captchaRef} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} theme={'dark'} required />
          </div>
          <button type="submit" className="btn btn-primary">Send &nbsp;<AiOutlineSend/></button>
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Contact