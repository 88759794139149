export const leadership =[
    {
        title: 'Board Member',
        organization: 'Supreme Student Government',
        address: 'Region VI - Western Visayas',
        dates: '2012 - 2013'
    },
    {
        title: 'President',
        organization: 'Supreme Student Government',
        address: 'Division of Negros Occidental',
        dates: '2012 - 2013'
    },
    {
        title: 'President',
        organization: 'Supreme Student Government',
        address: 'Rafael B. Lacson Memorial HS',
        dates: '2012 - 2013'
    },
    {
        title: 'Boy Mayor',
        organization: 'Boys and Girls Week',
        address: 'City of Talisay, Negros Occidental',
        dates: '2012'
    },
    {
        title: 'Trainor',
        organization: 'Youth for Mary and Christ',
        address: 'Rafael B. Lacson Memorial HS',
        dates: '2012 - 2013'
    }
]