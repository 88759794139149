import cloudemployee from '../../../assets/CloudEmployee.jpg'
import miller from '../../../assets/Miller.png'
import globe from '../../../assets/Globe.png'
import accenture from '../../../assets/Accenture.png'
import analog from '../../../assets/Analog.png'
import maxim from '../../../assets/Maxim.png'

export const jobs = [
    {
        title: 'Fullstack Developer',
        name: 'Cloud Employee',
        dates: 'June 2023 - Present',
        logo: cloudemployee,
        descriptions: [
            {
                title: "Job Responsibilities",
                paragraphs: 
                [
                    "To be updated"
                ]
            },
            {
                title: "Key Accomplishments",
                paragraphs: 
                [
                    "To be updated"
                ]
            }
        ]
    },
    {
        title: 'Software Developer',
        name: 'Miller Code Factory',
        dates: 'January 2022 - June 2023',
        logo: miller,
        descriptions: [
            {
                title: "Job Responsibilities",
                paragraphs: 
                [
                    "Diagnose and fix bugs, while devising solutions for loosely defined issues to ensure software quality",
                    "Submit pull requests of code changes to GitHub for review and feedback, ensuring code is well-documented and maintainable",
                    "Review code changes made by colleagues, providing constructive feedback and suggestions for improvement",
                    "Deploy code changes to integration, pre-production, and production environments, ensuring that changes are stable and ready for end-users",
                    "Attend regular scrum meetings and sprint plannings, collaboratively working with team members to meet sprint and project goals on time"
                ]
            },
            {
                title: "Key Accomplishments",
                paragraphs: 
                [
                    "Devised a solution to fix issues in over 150K product prices on client's e-commerce site, resulting in increased client satisfaction",
                    "Developed the front-end of a Purchase Order Web Application using Vue.js and Typescript, improving the user experience and functionality",
                    "Customized an out-of-the-box solution to build a B2B e-commerce website using ASP.NET MVC, meeting the client's specific requirements and needs"
                ]
            }
        ]
    },
    {
        title: 'Technical Support Engineer',
        name: 'Globe Group',
        dates: 'July 2021 - January 2022',
        logo: globe,
        descriptions: [
            {
                title: "Job Responsibilities",
                paragraphs: 
                [
                    "Maintained network device detection and connectivity across all domains throughout the country, ensuring network stability and security",
                    "Trained colleagues on the operation of a software for managing communication networks, improving team members' technical skills and knowledge",
                    "Attended regular meetings with international engineers and vendors to ensure project milestones were met and communication was effective"
                ]
            },
            {
                title: "Key Accomplishments",
                paragraphs: 
                [
                    "Collaborated with international engineers to repair a network of undetectable devices caused by an accidental deletion of files in a server, which had impacted the business for two weeks",
                    "Engineered the detection and connection of a new fiber optic network device to consumers' premises, expanding the network's reach"
                ]
            }
        ]    
    },
    {
        title: 'Application Development Associate',
        name: 'Accenture',
        dates: 'May 2020 - June 2021',
        logo: accenture,
        descriptions: [
            {
                title: "Job Responsibilities",
                paragraphs: 
                [
                    "Conducted testing on the B2B e-commerce site of an international brand selling active wears, bikes, and spare parts, ensuring the site was fully functional and user-friendly",
                    "Resolved technical issues on computers and network devices of clients retailing consumer electronics in the US, providing effective solutions to improve device performance"
                ]
            },
            {
                title: "Key Accomplishments",
                paragraphs: 
                [
                    "Completed boot camps on Web Development, Technologies, and Tools, including VS Code, Git, GitHub, Postman, ReactJS, Angular, Ionic, NodeJS, and Spartacus, enhancing technical skills and knowledge",
                    "Reached the semi-finals of the Ideas to Outcomes 2020: Innovate for Society Competition, showcasing innovative problem-solving skills and creativity",
                    "Volunteered for various outreach programs of the company, including the Hour of Code 2020, demonstrating a commitment to community involvement and social responsibility",
                    "Hosted several successful town hall meetings and social gatherings online, fostering team building and improving employee morale in a remote work environment",
                    "Achieved top scores on assessments for ReactJS and the Advanced Technology Analysts School, highlighting strong technical proficiency and expertise"
                ]
            }
        ]
    },
    {
        title: 'Equipment Engineer - Electronics',
        name: 'Analog Devices',
        dates: 'March 2021 - May 2020',
        logo: analog,
        descriptions: [
            {
                title: "Job Responsibilities",
                paragraphs: 
                [
                    "Maintained and repaired all configurations of National Instruments Semiconductor Test Systems, ensuring uninterrupted production",
                    "Tested the quality and safety of new and recommissioned test systems prior to using them in production, ensuring accurate and reliable test results",
                    "Attended regular meetings with international engineers and vendors to fix issues and align project goals, resulting in effective collaboration and problem-solving"
                ]
            },
            {
                title: "Key Accomplishments",
                paragraphs: 
                [
                    "Received an award for expediting the release of 19 upgraded test systems, resulting in improved efficiency and productivity",
                    "Developed several macros in Excel to automate the reporting of more than 300,000 entries of weekly production data, saving time and effort for the team",
                    "Resolved a two-month-long issue on a test system by hardware isolation and code debugging in LabView, resulting in the successful resolution of a critical production issue",
                    "Tested and released newly acquired multimillion-dollar systems capable of testing super and extremely high frequencies 5G devices, contributing to the company's technological advancements",
                    "Volunteered as a Technical Committee Member for the 2019 Skills Olympics of the company, contributing to the success of the event and showcasing technical expertise"
                ]
            }
        ]
    },
    {
        title: 'Student Intern',
        name: 'Maxim Integrated',
        dates: 'January 2016 - June 2016',
        logo: maxim,
        descriptions: [
            {
                title: "Job Responsibilities",
                paragraphs: 
                [
                    "Developed an arcade game using Arduino microcontroller, integrating the company's products such as LED drivers and photosensors, resulting in a fun and engaging marketing tool",
                    "Attended regular meetings with project managers and engineers, providing updates and collaborating to meet project goals"
                ]
            },
            {
                title: "Key Accomplishments",
                paragraphs: 
                [
                    "Demonstrated the developed arcade games during the 2016 Philippines Semiconductor and Electronics Convention and Exhibit held at the SMX Convention Center, Pasay City, showcasing the company's innovation and technical expertise",
                    "Contributed to the company's goal in achieving a three-peat win for the Best Booth Award during the above-stated convention, resulting in increased visibility and recognition for the company",
                ]
            }
        ]
    },
];
