import React, { useRef, useState } from 'react'
import  { FaEye, FaEyeSlash } from 'react-icons/fa'
import { projects } from './data/projects'
import Fade from 'react-reveal/Fade'
import './projects.css'

const Projects = () => {
  const contentRef = useRef();

  const [isAnimated, setIsAnimated] = useState(Array(projects.length).fill(false));

  const handleClick = (index) => {
    setIsAnimated(
      isAnimated.map((status, i) => {
        if (i === index) {
          return !status;
        }
        return status;
      })
    );
  };

  return (
    <div className="projects" id="portfolio">
      <div className="projects--title">Portfolio</div>
        <div className="projects--grid">
            {projects.map((project, index) => (
              <Fade>
                <div className="project">
                  <img className={`project__image ${isAnimated[index] ? "scroll-effect":""}`} style={{display: project.isCarousel ? 'none':'block'}} src={project.photos[0]} alt={project.photos[0]} />
                  <div className="project__image" style={{display: project.isCarousel ? "block":"none"}}>
                    <img className={`carousel__image ${isAnimated[index] ? "carousel-effect":""}`} src={project.photos[0]} alt={project.photos[0]}/>
                  </div>
                  <div className="project__details">
                    <div className="project--title">{project.title}</div>
                    <div className="project__details--description" style={isAnimated[index] ? { height: contentRef.current.scrollHeight + "px"} : { height: "0" }} ref={contentRef}> {project.description}</div>
                    <div className="project__technologies">
                      {project.technologies.map ((technology) => (
                        <div className="project__technologies--item">{technology}</div> 
                      ))}
                    </div>
                    <button className="btn--show" onClick={() => handleClick(index)}>{isAnimated[index] ? <FaEye/>:<FaEyeSlash/>}{isAnimated[index] ? "See Less":"See More"}</button>
                  </div>
                </div>
              </Fade>
            ))}
        </div>
    </div>
  )
}

export default Projects