import React, { useRef } from 'react'
import  { FaChevronDown } from 'react-icons/fa'
import './accordion.css'

const Accordion = ({item, toggleAccordion, index, toggleDetails}) => {
    const contentRef = useRef();

    return (
        <div className='accordion'>
            <div className={`accordion_item ${toggleDetails ? "accordion_item-active" : ""}`} onClick = {() => toggleAccordion(index)}>
                <img className="accordion_item_logo" src={item.logo} alt={item.logo} />
                <div className="accordion_item_details">
                    <div className='accordion_item_details_title'>{item.title}</div>
                    <div className='accordion_item_details_name'>{item.name}</div>
                    <div className='accordion_item_details_dates'>{item.dates}</div>
                </div>
                <FaChevronDown className={`accordion_item_btn ${toggleDetails ? "" : "accordion_item_btn_up"}`} />
            </div>
            <div className="accordion_content" style={toggleDetails ? { height: contentRef.current.scrollHeight + "px"} : { height: "0px" }} ref={contentRef} >
                {(() =>
                    item.descriptions?.map((description, index) => {
                        return(
                            <>
                                <div className='accordion_content_description_title' key={index}>{description.title}:</div>
                                <ul className='accordion_content_description_list'>
                                    {description.paragraphs?.map((paragraph, index) => {
                                        return (
                                            <li>{paragraph}</li>
                                        )
                                    })}
                                </ul>
                            </>
                        )
                    })
                )()}
            </div>
        </div>
    )
}

export default Accordion