import React from 'react'
import './leadership.css'

const Leadership = ({item}) => {
  return (                         
    <div className='leadership_details'>
      <p>{item.title}</p>
      <p>{item.organization}, <span>{item.address}</span></p>
      <p>{item.dates}</p>
    </div>
  )
}

export default Leadership