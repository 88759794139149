import eelms from '../../../assets/EELMS.png'
import rblmhs from '../../../assets/RBLMHS.png'
import tup from '../../../assets/TUP.png'

export const schools = [
    {
        title: 'Bachelor of Science in Electronics Engineering',
        name: 'Technological University of the Philippines Visayas',
        dates: '2013 - 2018',
        logo: tup,
        descriptions: [
            {
                title: "Key Accomplishments",
                paragraphs: 
                [
                    "Graduated with Latin Honors (Cum Laude)",
                    "Awarded Best Research Student for outstanding thesis project",
                    "Received Full Academic Scholarship from the Provincial Government of Negros Occidental",
                    "Served as a Student Extensionist for the university's electrification project in remote adopted communities"
                ]
            }
        ]
    },
    {
        title: 'Electronics Engineering Technology Diploma',
        name: 'Technological University of the Philippines Visayas',
        dates: '2013 - 2016',
        logo: tup,
        descriptions: [
            {
                title: "Key Accomplishments",
                paragraphs: 
                [
                    "Graduated as Gold Medalist, recognized as the 2nd Honored Graduate for outstanding academic achievements",
                    "Received Full Academic Scholarship from the Provincial Government of Negros Occidental",
                    "Placed 3rd out of 31 city and municipality representatives in the 2015 Panaad sa Negros Festival Agri Quiz Show",
                ]
            }
        ]
    },    
    {
        title: 'High School Diploma',
        name: 'Rafael B. Lacson Memorial High School',
        dates: '2009 - 2013',
        logo: rblmhs,
        descriptions: [
            {
                title: "Key Accomplishments",
                paragraphs: 
                [
                    "Graduated with the title of Class Valedictorian",
                    "Served as the President of the Division of Negros Occidental Federation of Supreme Student Government",
                    "Elected as the Boy Mayor for the 2012 Talisay City Boys and Girls Week",
                    "Awarded the Philippine Vice President's Leadership Award",
                    "Awarded the Philippine Senate Gold Medal for Academic Excellence",
                    "Awarded the 3rd Congressional District Award for Academic Excellence",
                    "Awarded the ABS-CBN Medal of Excellence",
                    "Awarded the Colegio San Agustin Bacolod Award of Excellence",
                    "Awarded the University of Negros Occidental Recoletos Award of Excellence",
                    "Awarded the Tana Dicang Award of Excellence",
                    "Champion of the 2012 Minuluan Festival Extemporaneous Speaking Contest",
                    "Champion of the 2012 City of Talisay Sports and Athletic Meet Extemporaneous Speaking Contest",
                    "1st Runner Up in the 2011 Minuluan Festival Extemporaneous Speaking Contest",
                    "1st Runner Up in the 2011 City of Talisay Sports and Athletic Meet Extemporaneous Speaking Contest",
                    "2nd Runner Up in the 2012 3rd Congressional District Sports and Athletic Meet Extemporaneous Speaking Contest",
                    "2nd Runner Up in the 2011 Minuluan Festival Debate Competition",
                    "3rd Runner Up in the 2012 Minuluan Festival Debate Competition",
                    "Semi-finalist in the 2012 ABS-CBN Provincial News Casting Competition"
                ]
            }
        ]
    },
    {
        title: 'Elementary School Diploma',
        name: 'Efigenio-Enrica Lizares Memorial School',
        dates: '2003 - 2009',
        logo: eelms,
        descriptions: [
            {
                title: "Key Accomplishments",
                paragraphs: 
                [
                    "Graduated with distinction and earned the title of Class First Honorable Mention",
                    "Champion of the 2009, 2008, and 2007 District of Talisay 1 Metrobank MTAP DepEd Math Challenge",
                    "Placed 2nd in the 2009 District of Talisay 1 Inter-school Math Quiz Bee",
                    "Placed 3rd in the 2008 and 2007 Division of Negros Occidental Metrobank MTAP DepEd Math Challenge"
                ]
            }
        ]
    }
]