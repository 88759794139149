import React from 'react'
import './footer.css'

const year = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="footer">
      Copyright &copy; {year} www.princedymosco.ph | All rights reserved.
    </div>
  )
}

export default Footer