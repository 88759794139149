import React, {useState} from 'react'
import { Fade } from 'react-reveal';
import { leadership } from './data/leadership'
import { jobs } from './data/jobs';
import { schools } from './data/schools';
import { trainings } from './data/trainings'

// Components
import Accordion from '../../components/accordion/Accordion'
import Training from '../../components/training/Training'
import Leadership from '../../components/leadership/Leadership'

import './qualifications.css'

const Qualifications = () => {
    const [toggleState, setToggleState] = useState(2);
    const [accordionState, setAccordionState] = useState(null);

    const toggleTab = (index) => {
        setToggleState(index);
        setAccordionState(null);
    };

    const toggleAccordion = (index) => accordionState === index ? setAccordionState(null) : setAccordionState(index);

    return (
        <div className="qualifications" id="experience">
            <div className="qualifications_title">Experience</div>
            <div className="tabs">
                <div className={`tabs_item ${toggleState === 2 ? "tabs_item-active" : ""}`} onClick={() => toggleTab(2)}>Work</div>
                <div className={`tabs_item ${toggleState === 1 ? "tabs_item-active" : ""}`} onClick={() => toggleTab(1)}>Education</div>
                <div className={`tabs_item ${toggleState === 3 ? "tabs_item-active" : ""}`} onClick={() => toggleTab(3)}>E-learning</div>
                <div className={`tabs_item ${toggleState === 4 ? "tabs_item-active" : ""}`} onClick={() => toggleTab(4)}>Leadership</div>
            </div>
            <div className="qualifications_content">
                <Fade big>
                    {(() => {
                        switch (toggleState) {
                            case 1:
                                return (
                                    <>
                                        {schools.map((school, index) => (
                                            <Accordion item={school} 
                                                toggleAccordion={toggleAccordion} 
                                                index={index}
                                                toggleDetails = {accordionState === index ? true : false} /> 
                                        ))}
                                    </>
                                )
                            case 2:
                                return (
                                    <>
                                        {jobs.map((job, index) => (
                                            <Accordion item={job} 
                                                toggleAccordion={toggleAccordion}
                                                index={index} 
                                                toggleDetails = {accordionState === index ? true : false} />
                                        ))}
                                    </>
                                )
                            case 3:
                                return (
                                    <>
                                        {trainings.map((training, index) => (
                                            <Training item={training} />
                                        ))}
                                    </>
                                )
                            case 4:
                                return (
                                    <>
                                        {leadership.map((leadership, index) => (
                                            <Leadership item={leadership} />
                                        ))}
                                    </>
                                )
                            default:
                                return null
                        }
                    })()}
                </Fade>
            </div>
        </div>
    )
}

export default Qualifications
