import giftcards from '../../../assets/giftcards.png'
import natours from '../../../assets/natours.png'
import pokedex from '../../../assets/pokedex.png'
import posystem from '../../../assets/posystem.png'

export const projects = [
    {
      title: 'Gift Cards Site',
      description: 'This online store sells corporate merchandise to employees. Payment is made through gift cards, and employees have the option to top up to add more credit to their cards.',
      technologies: ['ASP.NET', 'EpiServer', 'MS SQL', 'Postman', 'Restful API', 'JQuery', 'Javascript'],
      photos: [giftcards],
      isCarousel: false
    },
    {
      title: 'Camping Booking Site',
      description: "This site was created during a 42-hour Udemy course focused on backend development. It is a booking site for camping enthusiasts who enjoy multi-day trips.",
      technologies: ['NodeJS', 'Restful API', 'Postman', 'MongoDB', 'Mongoose', 'Express', 'Sendgrid', 'Stripe'],
      photos: [natours],
      isCarousel: false
    },
    {
      title: 'Pokemon Deck',
      description: "This was my output from a software development bootcamp I attended. It serves as a go-to reference for discovering and learning about various Pokémon.",
      technologies: ['Ionic', 'Angular', 'Restful API', 'Postman'],
      photos: [pokedex],
      isCarousel: true
    },
    {
      title: 'Purchase Order System',
      description: "This system offers a solution for managing, tracking, and fulfilling purchase order requests. It also includes an SSO feature that uses a Microsoft account.",
      technologies: ['VueJS', 'Ionic', 'Bootstrap', 'MS SQL'],
      photos: [posystem],
      isCarousel: true
    }
  ]