import React from 'react'
import './header.css'
import Typewriter from 'typewriter-effect';
import { FiDownloadCloud, FiArrowRight } from 'react-icons/fi'

import Socials from '../../components/socials/Socials'

import avatar from '../../assets/avatar.png'

const Header = () => {
  return (
    <div className="header" id="home">
      <img className="header_avatar" src={avatar} alt="avatar" />
      <div className="header_greetings">Hey there!<span className="header_wave">👋</span>Meet</div>
      <div className="header_fullname">PRINCE GEARY DYMOSCO</div>
      <Typewriter
        options={{
          strings: [
            'a licensed <span class="header_occupation">Electronics Engineer</span>', 
            'turned <span class="header_occupation">Fullstack Developer</span>.'],
          autoStart: true,
          loop: true,
          delay: 50,
          deleteSpeed: 50,
          pauseFor: 1000
        }}
      />
      <Socials />
      <div className="header_CTA">
        <a className="btn btn-primary" target="_blank" href="https://drive.google.com/file/d/1zbmhGzeiRm_ndd9fKOvaT1ldLEyHo-Fy/view?usp=sharing" rel="noreferrer">Resume&nbsp;<FiDownloadCloud/></a>
        <a className="btn btn-secondary" href="#connect">Get in Touch&nbsp;<FiArrowRight/></a>
      </div>
    </div>
  )
}

export default Header
