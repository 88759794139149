import React, { useState, useEffect } from 'react'
import './navbar.css'
import { IoIosCloseCircleOutline, IoIosMenu } from 'react-icons/io'

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);

	// Opaque Top Bar Menu
	useEffect(() => {
		const onScroll = () => {
		  if (window.scrollY > 50) {
			setIsScrolled(true);
		  } else {
			setIsScrolled(false);
		  }
		}
		window.addEventListener("scroll", onScroll);
	
		return () => window.removeEventListener("scroll", onScroll);
	})
	
	// Sidebar Menu Toggle
	const toggleMenu = () => {
		setIsOpen(!isOpen);
	}

	// Links
  	const links = [
		{ name: "Home", url: "#home" },
		{ name: "About", url: "#me" },
		{ name: "Experience", url: "#experience" },
		{ name: "Portfolio", url: "#portfolio" },
		{ name: "Contact", url: "#connect" }
    ];

  	return (
      <div className="menu">
		<div className={`menu__bar ${isScrolled ? "menu__bar--opaque":""}`}>
			<div className="menu__desktop">
				{links.map(link => (<a className="menu__item" href={link.url}>{link.name}</a>))}
			</div>
			<IoIosMenu  className="menu__burger" onClick={() => toggleMenu()}/>
		</div>
		<Panel isOpen={isOpen} links={links} toggle={toggleMenu}/>
      </div>
    );
};

const Panel = ({isOpen, links, toggle}) => {
  	return (
		<div className={`panel ${isOpen ? "panel--open" : ""}`}>
			<div className="panel__items">
			    {links.map(link => (<a href={link.url} onClick={() => toggle()}>{ link.name }</a>))}
            </div>
            <IoIosCloseCircleOutline className="menu__close" onClick={() => toggle()} />
		</div>
    )
};

export default Navbar