import React from 'react'
import './summary.css'
import CountUp from 'react-countup';
import display_photo from '../../assets/display_photo.png'

const Summary = () => {
    return (
        <div className="summary" id="me">
            <img className="summary_photo" src={display_photo} alt="" />
            <div className="summary_content">
                <div className="summary_title">About the developer</div>
                <div className="summary_text">
                    <p>
                        Prince is a Filipino Electronics Engineer with a knack for problem-solving and a growing passion for coding 💡. 
                        Graduating with honors from the Technological University of the Philippines Visayas, he quickly secured a job as an Equipment Engineer at Analog Devices Inc. (ADI) ⚙️. 
                        There, he earned a Spot Award for his contributions to the rapid upgrading and deployment of test machines critical to 5G chip testing. 
                        Working in the fast-paced world of semiconductor manufacturing, he discovered a hidden talent for automating tasks with Excel macros, developing tools that enabled managers to better analyze manufacturing data 📊. 
                        This reignited his love for coding, a skill he'd honed during college through robotics and web development projects 🤖.
                    </p>
                    <p>
                        Seeking to dive deeper into the world of coding, Prince transitioned to Accenture, where he honed his skills and led a team to the semi-finals of the Ideas to Outcomes: Innovate for Society competition 🏆. 
                        Today, he's a Fullstack Developer at a UK-based pet grooming software company, where he played a key role in launching their MVP 🐶.
                    </p>
                    <p>
                        Prince is a dedicated professional who thrives on challenges and is committed to delivering results. 
                        Beyond his technical expertise, he is a compassionate team player with a strong work ethic. 
                        When not immersed in code, he enjoys the serenity of nature or the camaraderie of friends.
                    </p>
                </div>
                <div className="summary_years">
                    <div className="summary_years_item">
                        <CountUp className="summary_years_item_count_up" end={1} duration={1} enableScrollSpy={true}/> 
                        <div className="summary_years_description">Year in Semicon Manufacturing</div>
                    </div>
                    <div className="summary_years_item">
                        <div><CountUp className="summary_years_item_count_up" end={3} duration={1} enableScrollSpy={true}/> <span className='summary_years_item_count_up'>+</span></div>
                        <div className="summary_years_description">Years in Software Development</div>
                    </div>
                    <div className="summary_years_item">
                        <CountUp className="summary_years_item_count_up" end={4} duration={1} enableScrollSpy={true}/> 
                        <div className="summary_years_description">Web Projects</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Summary