import udemy from '../../../assets/Udemy.png'
import sololearn from '../../../assets/SoloLearn.png'

export const trainings = [
    {
        title: 'Node.js, Express, MongoDB: The Complete Bootcamp',
        platform: 'Udemy',
        logo: udemy
    },
    {
        title: '2022 Complete Python Bootcamp From Zero to Hero',
        platform: 'Udemy',
        logo: udemy
    },
    {
        title: 'Adobe Photoshop Photo Editing Ultimate Guide 2022',
        platform: 'Udemy',
        logo: udemy
    },
    {
        title: 'The Complete ASP.NET MVC 5',
        platform: 'Udemy',
        logo: udemy
    },
    {
        title: 'React - The Complete Guide',
        platform: 'Udemy',
        logo: udemy
    },
    {
        title: 'Angular - The Complete Guide (2020 Edition)',
        platform: 'Udemy',
        logo: udemy
    },
    {
        title: 'Git Complete: The definitive, step-by-step guide to Git',
        platform: 'Udemy',
        logo: udemy
    },
    {
        title: 'Build iOS, Android & Web Apps with Ionic & Angular',
        platform: 'Udemy',
        logo: udemy
    },
    {
        title: 'Learn Visual Studio Code (2020)',
        platform: 'Udemy',
        logo: udemy
    },
    {
        title: 'HTML course',
        platform: 'SoloLearn',
        logo: sololearn
    }
]