import './App.css'
import React from 'react'
import Navbar from './containers/navbar/Navbar'
import Header from './containers/header/Header'
import Projects from './containers/projects/Projects'
import Summary from './components/summary/Summary'
import Qualifications from './containers/qualifications/Qualifications'
import Contact from './containers/contact/Contact'
import Gallery from './containers/gallery/Gallery'
import Footer from './components/footer/Footer'

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Header />
      <div className="mid-section">
        <Summary />
        <Gallery />
        <Qualifications />
        <Projects />
      </div>
      <Contact />
      <Footer />
    </div>
  )
}

export default App