import React, { useState } from 'react'
import './socials.css'

import { BsLinkedin, BsTelegram, BsMessenger, BsInstagram } from 'react-icons/bs'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const Socials = () => {
  const [showModal, setShowModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [channel, setChannel] = useState('');

  const handleLinkClick = (event, href, channel) => {
    event.preventDefault();
    setRedirectUrl(href);
    setShowModal(true);
    setChannel(channel)
  };

  const clickConfirm = () => {
    document.querySelector('.modal_content').classList.add('scale-out-center');
    setTimeout(() => {
      setShowModal(false);
      window.location.href = redirectUrl;
    }, 100);
  };

  const clickCancel = () => {
    document.querySelector('.modal_content').classList.add('scale-out-center');
    setTimeout(() => {
      setShowModal(false);
    }, 100);
  };

  return (
    <>
      <div className="socials">
        <a className="linkedin" href="https://linkedin.com/in/princedymosco/" onClick={(event) => handleLinkClick(event, 'https://linkedin.com/in/princedymosco/', 'Linkedin')}><BsLinkedin/></a>
        <a className="telegram" href="https://t.me/princedymosco" onClick={(event) => handleLinkClick(event, 'https://t.me/princedymosco', 'Telegram')}><BsTelegram/></a>
        <a className="messenger" href="https://m.me/princegearyd" onClick={(event) => handleLinkClick(event, 'https://m.me/princegearyd', 'Messenger')}><BsMessenger/></a>
        <a className="instagram" href="https://instagram.com/princedymosco/" onClick={(event) => handleLinkClick(event, 'https://instagram.com/princedymosco/', 'Instagram')}><BsInstagram/></a>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal_content">
            <div className="modal_icon">
              <AiOutlineInfoCircle />
            </div>
            <p>Leave this page for {channel}?</p>
            <div className="modal_btn">
              <button className="btn btn-primary" onClick={clickConfirm}>Yes</button>
              <button className="btn btn-secondary" onClick={clickCancel}>No</button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Socials
