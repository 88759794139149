import React from 'react'
import './training.css'

const Training = ({item}) => {
  return (                         
    <div className='training'>
      <img className="training_logo" src={item.logo} alt={item.platform} />
      <div className="training_details">
        <p><b>{item.title}</b></p>
        <p>{item.platform}</p>
      </div>
    </div>
  )
}

export default Training