import React from 'react'
import  './gallery.css'

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'

// Featured Photos
import photo_1 from '../../assets/photo_1.jpg'
import photo_2 from '../../assets/photo_2.jpg'
import photo_3 from '../../assets/photo_3.png'
import photo_4 from '../../assets/photo_4.jpg'
import photo_5 from '../../assets/photo_5.jpg'

const testimonials = [photo_1, photo_2, photo_3, photo_4, photo_5]

const Gallery = () => {
  
  return (
    <Swiper
      modules={[Autoplay]}
      onSwiper={(swiper) => (window.swiper = swiper)}
      breakpoints={{
        640: { slidesPerView: 5}
      }}
      slidesPerView={3}
      autoplay={{delay: 1000}}
      loop
      touchMoveStopPropagation={false}
    >
      {testimonials.map((testimonial, index) => (
        <SwiperSlide>
          <div className="filmstrip">
              <img className="slider--image" src={testimonial} alt=""/>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Gallery